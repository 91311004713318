.game-field {
    display: flex;
    flex-wrap: wrap;
    gap: 10; 
    margin: 0; 
    padding: 0; 
    overflow: auto; 
    background-color: #fff;
    position: relative; 
  }

  .zoom-panel {
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 2px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    height: 100px;
  }


  .container {
    display: flex;
    gap: 10px; /* Optional: space between the boxes */
    margin: 10px;
    background-color: #fff;
  }

  