
.sound-panel {
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 2px solid #ccc;
    width: 100%;
    box-sizing: border-box;
  }
  .volume-control input[type="range"] {
    width: 100%;
  }
  
  .volume-control button {
    background-color: #fff; /* Білий фон для кнопки */
    border: 1px solid #000; /* Чорна рамка для кнопки */
    padding: 10px 20px; /* Відступи всередині кнопки */
    font-size: 16px; /* Розмір шрифту */
    cursor: pointer; /* Курсор як вказівник */
    position: relative; /* Для абсолютного позиціонування псевдоелемента */
    margin: 0
  }
  
  .volume-control .sound-off {
    color: red; /* Текст червоного кольору */
  }
  
  
  .volume-control label {
    margin-right: 8px;
  }

  .h4{
    margin: 2px
  }