/* src/ResourcePanel.css */
.resource-panel {
    display:grid;
    gap: 10; 
    background-color: #f0f0f0;
    padding: 10px;
    border-bottom: 2px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    
  }
 